@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
    height: 100%;
}

* {
    font-family: 'Outfit', sans-serif;
}

h1 {
    font-size: 1.5em;
    color: #333;
    font-weight: bold;
    margin-bottom: 1em;
}

h2 {
    font-size: 1.3em;
    color: #333;
    font-weight: bold;
    margin-bottom: 1em;
}

h3 {
    font-size: 1.2em;
    color: #333;
    font-weight: bold;
    margin-bottom: 1em;
}

label {
    font-weight: bold;
}

p {
    margin-bottom: 1em;
}

ul {
    list-style-type: disc;
    margin-left: 1.5em;
    margin-bottom: 1em;
}

ol {
    list-style-type: decimal;
    margin-left: 1.5em;
    margin-bottom: 1em;
}

.promo-text {
    animation: scrollUp 10s ease forwards;
    position: absolute;
    white-space: nowrap;
    font-size: 1.5em;
}

/* Keyframes for scroll-up animation */
@keyframes scrollUp {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    10% {
        opacity: 1;
        transform: translateY(0);
    }
    90% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}
